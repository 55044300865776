import MergedInfoCell from '../components/layout/MergedInfoCell';

export const parseMergedInfo = (mergedInfoStr) => {
    if (!mergedInfoStr) return [];
  
    // Split the string using '<<' and '>>' as delimiters
    const entries = mergedInfoStr.match(/<<[^>]*>>/g);
    if (!entries) return [];
  
    return entries.map((entryStr) => {
      const entry = {};
      // Remove the '<<' and '>>' and split the key-value pairs
      entryStr
        .replace(/<<|>>/g, '')
        .split(';')
        .forEach((pair) => {
          const [key, value] = pair.split(/:(.+)/).map((s) => s.trim());
          if (key === 'url') {
            // Ensure 'url' is always an array
            entry[key] = value ? [value] : [];
          } else {
            entry[key] = value !== 'None' ? value : null;
          }
        });
      return entry;
    });
  };
  

// export const exportToCSV = (filteredData, columns) => {
//     const visibleColumns = columns.filter(col => col.visible);
    
//     const exportData = filteredData.map(row => {
//         const exportRow = {};
//         visibleColumns.forEach(col => {
//             if (col.key === 'email') {
//                 exportRow[col.label] = Object.entries(row[col.key])
//                     .map(([provider, email]) => `${provider}: ${email}`)
//                     .join('; ');
//             } else {
//                 exportRow[col.label] = row[col.key];
//             }
//         });
//         return exportRow;
//     });

//     const csv = Papa.unparse(exportData, {
//         quotes: true,
//         header: true
//     });

//     const blob = new Blob(["\ufeff", csv], { type: 'text/csv;charset=utf-8;' });
//     const link = document.createElement('a');
//     const url = URL.createObjectURL(blob);
//     link.setAttribute('href', url);
//     link.setAttribute('download', 'table-export.csv');
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
// };

export const renderCell = (column, value, rowId) => {
    if (column.key === 'merged_info') {
      return <MergedInfoCell mergedInfo={value} rowId={rowId} />;
    }
    if (column.key === 'isApproved') {
      return value ? 'Yes' : 'No';
    }
    return value;
  };