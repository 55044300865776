import React, { useRef } from 'react';
import ActionMenu from './ActionMenu';
import { FaEllipsisH, FaCopy, FaEdit } from 'react-icons/fa';

const Tooltip = ({ data, isVisible, onClose, onSuggestChanges }) => {
  const tooltipRef = useRef(null);

  if (!data || !isVisible) return null;

  return (
    <div 
      ref={tooltipRef}
      className="bg-white rounded-lg shadow-lg p-4 border border-gray-200"
      style={{
        width: '320px',
        pointerEvents: 'auto',
        backgroundColor: 'white',
        zIndex: 20,
        wordBreak: 'break-word'
      }}
    >
      <div className="flex justify-between items-start mb-2">
        <div className="text-sm font-semibold text-gray-800">
          {data.investigational_agent}
        </div>
        <div className="flex items-center gap-1">
          <ActionMenu 
            data={data}
            onSuggestChanges={onSuggestChanges}
          />
          <button
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            className="p-1.5 hover:bg-gray-100 rounded-full text-gray-600 transition-colors"
            title="Close"
          >
            <svg className="w-3.5 h-3.5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M6 18L18 6M6 6l12 12" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
      </div>
      <div className="space-y-1 text-sm text-gray-600">
        <p><span className="font-medium">Phase:</span> {data.phase}</p>
        <p><span className="font-medium">Target:</span> {data.target}</p>
        <p><span className="font-medium">Modality:</span> {data.modality}</p>
        <p><span className="font-medium">Sponsor:</span> {Array.isArray(data.sponsor_names) ? data.sponsor_names.join(', ') : data.sponsor_names}</p>
        <p><span className="font-medium">NCT ID:</span> {data.nct_id}</p>
      </div>
    </div>
  );
};

export default Tooltip;
