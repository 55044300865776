import React from 'react';
import { FaEnvelope, FaInfoCircle, FaTable } from 'react-icons/fa';
import { MdRadar } from 'react-icons/md';
import PropTypes from 'prop-types';

function Sidebar({ activeTab, onTabChange }) {
  return (
    <div className="fixed top-0 left-0 h-full w-16 flex flex-col bg-[#852A45] z-50">
      <div className="flex flex-col items-center mt-24">
        <SidebarIcon 
          icon={<MdRadar size="20" />} 
          text="Trial Landscape" 
          active={activeTab === 'plot'}
          onClick={() => onTabChange('plot')}
        />
        <SidebarIcon 
          icon={<FaTable size="20" />} 
          text="Trials Table" 
          active={activeTab === 'table'}
          onClick={() => onTabChange('table')}
        />
        <SidebarIcon 
          icon={<FaEnvelope size="20" />} 
          text="Get in Touch" 
          active={activeTab === 'feedback'}
          onClick={() => onTabChange('feedback')}
        />
        <SidebarIcon 
          icon={<FaInfoCircle size="20" />} 
          text="About" 
          active={activeTab === 'about'}
          onClick={() => onTabChange('about')}
        />
      </div>
    </div>
  );
}

const SidebarIcon = ({ icon, text = 'Tooltip', active = false, onClick }) => (
  <div 
    className={`relative flex items-center justify-center h-12 w-12 mt-2 mb-2 mx-auto 
      ${active ? 'bg-white text-[#852A45] rounded-xl' : 'text-white hover:bg-white hover:text-[#852A45] rounded-3xl hover:rounded-xl'} 
      transition-all duration-300 ease-linear cursor-pointer group`}
    onClick={onClick}
  >
    {icon}
    <span className="absolute w-auto p-2 m-2 min-w-max left-14 rounded-md shadow-md
      text-white bg-[#852A45] text-xs font-bold transition-all duration-100 scale-0 origin-left group-hover:scale-100">
      {text}
    </span>
  </div>
);

Sidebar.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired
};

SidebarIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default Sidebar;