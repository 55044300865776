import React, { useState, useContext } from 'react';
import { WebSocketContext } from '../../context/WebSocketContext';

const FeedbackModal = ({ isOpen, onClose, rowData, category = 'trial_table' }) => {
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState('');
  const { sendFeedback } = useContext(WebSocketContext);

  const handleSubmit = async () => {
    try {
      sendFeedback(feedback, email, { ...rowData, category });
      onClose(true); // Pass true to indicate success
      setFeedback('');
      setEmail('');
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 flex items-center justify-center"
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 99999999999
      }}
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose(false);
      }}
    >
      <div 
        className="bg-white p-6 rounded-lg w-96 relative"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-bold">Submit Feedback</h3>
          <button 
            onClick={() => onClose(false)}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>
        <textarea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          className="w-full h-32 border p-2 mb-4 rounded"
          placeholder="Enter your feedback..."
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full border p-2 mb-4 rounded"
          placeholder="Email (optional) - if you'd like to hear back from us"
        />
        <div className="flex justify-end gap-2">
          <button
            onClick={() => onClose(false)}
            className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
