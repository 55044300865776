import { useMemo } from 'react';

/**
 * Prepares the phase configuration by calculating radii and colors for each phase.
 */
export const preparePhaseConfig = (phases, Rmax) => {
  const totalArea = Math.PI * Rmax * Rmax;
  const approvedArea = (2 / 11) * totalArea;
  const outerPhaseArea = (3 / 11) * totalArea;

  const areaFractions = [
    0,
    approvedArea / totalArea,
    (approvedArea + outerPhaseArea) / totalArea,
    (approvedArea + 2 * outerPhaseArea) / totalArea,
    1,
  ];

  const radii = areaFractions.map((fraction) => Rmax * Math.sqrt(fraction));

  const getColorForPhase = (phase) => {
    switch (phase) {
      case 'PHASE1':
        return '#f0f0f0';
      case 'PHASE2':
        return '#e8e8e8';
      case 'PHASE3':
        return '#e0e0e0';
      case 'APPROVED':
        return '#d4edda';
      default:
        return '#ffffff';
    }
  };

  return phases.reduce((config, phase, i) => {
    config[phase] = {
      innerRadius: radii[i],
      outerRadius: radii[i + 1],
      color: getColorForPhase(phase),
    };
    return config;
  }, {});
};

/**
 * Transforms the trial data by cleaning it and organizing targets and modalities.
 */
// export const transformTrialData = (trialLandscapeData, targetCount) => {
//     // Map 'PHASE4' to 'APPROVED'
//     const cleanedData = trialLandscapeData.map((trial) => {
//       if (trial.phase === 'PHASE4') {
//         return { ...trial, phase: 'APPROVED' };
//       }
//       return trial;
//     });
  
//     // Group trials by target and compound
//     const aggregatedTrials = cleanedData.reduce((acc, trial) => {
//       const key = `${trial.target_short}-${trial.investigational_agent}`;
//       if (!acc[key]) {
//         acc[key] = {
//           ...trial,
//           trials: [trial]
//         };
//       } else {
//         acc[key].trials.push(trial);
//       }
//       return acc;
//     }, {});
  
//     // Extract top targets using the aggregated data
//     const targetCompoundsMap = Object.values(aggregatedTrials).reduce((map, trial) => {
//       const target = trial.target_short;
//       const agent = trial.investigational_agent;
//       if (
//         target &&
//         !['not target specific', 'not found', 'none', 'not applicable'].includes(target.toLowerCase()) &&
//         agent
//       ) {
//         if (!map[target]) {
//           map[target] = new Set();
//         }
//         map[target].add(agent.trim());
//       }
//       return map;
//     }, {});
  
//     // Rest of the function remains the same
//     const targetCounts = Object.entries(targetCompoundsMap).reduce(
//       (counts, [target, compoundsSet]) => {
//         counts[target] = compoundsSet.size;
//         return counts;
//       },
//       {}
//     );
  
//     const sortedTargets = Object.entries(targetCounts)
//       .sort((a, b) => b[1] - a[1])
//       .slice(0, targetCount)
//       .map(([target]) => target);
  
//     const angleStep = 360 / sortedTargets.length;
//     const updatedTargets = sortedTargets.map((target, index) => ({
//       name: target,
//       startAngle: index * angleStep,
//       endAngle: (index + 1) * angleStep,
//     }));
  
//     // Transform aggregated data
//     const transformedData = Object.values(aggregatedTrials)
//       .map((trial) => {
//         const targetObj = updatedTargets.find((t) => t.name === trial.target_short);
//         if (!targetObj) return null;
  
//         const modality = trial.modality || 'Unknown';
  
//         return {
//           ...trial,
//           target: trial.target_short,
//           modality,
//         };
//       })
//       .filter(Boolean);
  
//     const modalitiesSet = new Set(transformedData.map((agent) => agent.modality));
//     const modalitiesArray = Array.from(modalitiesSet);
  
//     const colors = [
//       '#FF6B6B', '#4ECDC4', '#FFB347', '#9B59B6', '#06D6A0',
//       '#118AB2', '#073B4C', '#EF476F', '#FFC43D', '#D4A373',
//     ];
  
//     const modalityColorMap = modalitiesArray.reduce((map, modality, index) => {
//       map[modality] = colors[index % colors.length];
//       return map;
//     }, {});
  
//     return {
//       updatedTargets,
//       transformedData,
//       modalitiesArray,
//       modalityColorMap,
//     };
//   };
  
/**
 * Calculates positions for each trial marker on the chart.
 */
export const calculateTrialPositions = (trialData, targets, phaseConfig, centerX, centerY) => {
  const trialsByTargetPhase = trialData.reduce((acc, agentData) => {
    const key = `${agentData.target}-${agentData.phase}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(agentData);
    return acc;
  }, {});

  return Object.values(trialsByTargetPhase)
    .flatMap((trialsInGroup) => {
      const trialsCount = trialsInGroup.length;

      return trialsInGroup.map((agentData, index) => {
        const target = targets.find((t) => t.name === agentData.target);
        if (!target) return null;

        const phase = agentData.phase;
        const phaseConfigData = phaseConfig[phase];
        if (!phaseConfigData) {
          console.error(`Phase configuration not found for phase: ${phase}`);
          return null;
        }

        const { innerRadius, outerRadius } = phaseConfigData;
        const angularRange = target.endAngle - target.startAngle;

        const ringWidth = outerRadius - innerRadius;
        const effectiveInnerRadius = innerRadius + ringWidth * 0.1;
        const effectiveOuterRadius = outerRadius - ringWidth * 0.1;

        const angularStep = angularRange / trialsCount;
        const angle = target.startAngle + index * angularStep + angularStep / 2;
        const radius = effectiveInnerRadius + (effectiveOuterRadius - effectiveInnerRadius) / 2;

        const radianAngle = (angle * Math.PI) / 180;
        const x = centerX + Math.cos(radianAngle) * radius;
        const y = centerY + Math.sin(radianAngle) * radius;

        return {
          ...agentData,
          x,
          y,
        };
      });
    })
    .filter(Boolean);
};
