import React, { useState } from 'react';
import { WebSocketProvider } from './context/WebSocketContext';
import { TargetsProvider } from './context/TargetsContext';
import UserInput from './components/forms/UserInput';
import Tabs from './components/layout/Tabs';
import TrialLandscape from './components/trial_landscape/TrialLandscape';
import Sidebar from './components/layout/Sidebar';
import MessageTab from './components/feedback/MessageTab';
import AboutTab from './components/about/AboutTab';

function App() {
  const [submittedData, setSubmittedData] = useState(null);
  const [activeTab, setActiveTab] = useState('plot');

  const handleUserSubmit = (data) => {
    setSubmittedData(data);
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'plot':
      case 'table':
        return <TrialLandscape activeTab={activeTab} onTabChange={setActiveTab} />;
      case 'feedback':
        return <MessageTab />;
      case 'about':
        return <AboutTab onTabChange={setActiveTab} />;
      default:
        return null;
    }
  };

  return (
    <TargetsProvider>
      <WebSocketProvider>
        <div className="flex">
          <Sidebar activeTab={activeTab} onTabChange={setActiveTab} />
          <div className="flex-1">
            <header style={{
              backgroundColor: '#852A45',
              color: 'white',
              padding: '12px',
              position: 'absolute',
              left: 0,
              right: 0,
              zIndex: 40
            }}>
              <div className="container mx-auto relative max-w-[1600px]" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <div>
                  <h1 style={{
                    margin: 0,
                    fontSize: '24px',
                    marginBottom: '4px',
                    textAlign: 'center'
                  }}>Clinical Trial Landscape</h1>
                  <div style={{
                    fontSize: '12px',
                    lineHeight: '1.4',
                    textAlign: 'center'
                  }}>
                    Based on clinical-trials.gov, and augmented with AI agents
                  </div>
                </div>
              </div>
            </header>
            <div className="container mx-auto px-4 py-2 max-w-[1600px] mt-20">
              {renderActiveTab()}
            </div>
          </div>
        </div>
      </WebSocketProvider>
    </TargetsProvider>
  );
}

export default App;
