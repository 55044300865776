import { useState, useEffect, useRef } from 'react';
import { forceSimulation, forceLink, forceCollide, forceManyBody } from 'd3-force';

/**
 * Custom hook for label simulation to avoid overlaps.
 */
export const useLabelSimulation = (trials) => {
  const [labelPositions, setLabelPositions] = useState([]);
  const simulationRef = useRef();

  useEffect(() => {
    if (trials.length === 0) return;

    // Initialize nodes
    const markerNodes = trials.map((d, i) => ({
      index: i,
      x: d.x,
      y: d.y,
      fx: d.x,
      fy: d.y,
      type: 'marker',
    }));

    const labelNodes = trials.map((d, i) => ({
      index: trials.length + i,
      x: d.x,
      y: d.y - 15,
      trial: d,
      type: 'label',
    }));

    const nodes = [...markerNodes, ...labelNodes];

    const links = labelNodes.map((labelNode, i) => ({
      source: markerNodes[i],
      target: labelNode,
    }));

    // Adjust force parameters
    const chargeStrength = -30;
    const collisionRadius = 14;
    const linkStrength = 1;
    const labelForceStrength = 0.1;

    // Initialize the simulation
    simulationRef.current = forceSimulation(nodes)
      .force('link', forceLink(links).distance(20).strength(linkStrength))
      .force('collision', forceCollide(collisionRadius))
      .force('charge', forceManyBody().strength(chargeStrength))
      .force('x', () => {
        for (const node of nodes) {
          if (node.type === 'label') {
            node.vx += (node.trial.x - node.x) * labelForceStrength;
          }
        }
      })
      .force('y', () => {
        for (const node of nodes) {
          if (node.type === 'label') {
            node.vy += (node.trial.y - node.y) * labelForceStrength;
          }
        }
      })
      .on('tick', () => {
        const updatedLabelPositions = labelNodes.map((d) => ({
          x: d.x,
          y: d.y,
          trial: d.trial,
        }));
        setLabelPositions(updatedLabelPositions);
      });

    // Cleanup on unmount
    return () => {
      simulationRef.current.stop();
    };
  }, [trials]);

  return labelPositions;
};
