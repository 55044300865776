import React, { useState, useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { WebSocketContext } from '../../context/WebSocketContext';
import TargetTrialPhasePlot from './components/TargetTrialPhasePlot';
import TrialDataTable from './components/TrialDataTable';
import ConditionSelect from './components/ConditionSelect';
import useDrugLandscapeData from './hooks/useDrugLandscapeData';

// // Export trial landscape data to CSV
// const exportToCSV = (data) => {
//   if (!data || data.length === 0) return;

//   // Define CSV headers based on the data structure
//   const headers = Object.keys(data[0]);
//   const csvContent = [
//     headers.join(','), // CSV header row
//     ...data.map(row => 
//       headers.map(header => {
//         // Handle values that might contain commas
//         const value = row[header]?.toString() || '';
//         return value.includes(',') ? `"${value}"` : value;
//       }).join(',')
//     )
//   ].join('\n');

//   // Create a Blob containing the CSV data
//   const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
//   const url = URL.createObjectURL(blob);
  
//   // Create a temporary link element and trigger download
//   const link = document.createElement('a');
//   link.setAttribute('href', url);
//   link.setAttribute('download', 'trial_landscape_data.csv');
//   document.body.appendChild(link);
//   link.click();
  
//   // Clean up
//   document.body.removeChild(link);
//   URL.revokeObjectURL(url);
// };

const TrialLandscape = ({ initialTargetCount = 8, activeTab, onTabChange }) => {
  const { trialLandscapeData } = useContext(WebSocketContext);
  const [selectedCondition, setSelectedCondition] = useState([]);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [targetCount] = useState(initialTargetCount);

  const conditions = useMemo(() => {
    return trialLandscapeData?.length 
      ? [...new Set(trialLandscapeData.map(trial => trial.condition))]
      : [];
  }, [trialLandscapeData]);

  useEffect(() => {
    // Only run once when conditions are first loaded
    if (conditions.length > 0 && !hasInitialized) {
      const cancerConditions = conditions.filter(condition => 
        condition.toLowerCase().includes('cancer') || 
        condition.toLowerCase().includes('tumor') ||
        condition.toLowerCase().includes('carcinoma')
      );
      
      if (cancerConditions.length > 0) {
        setSelectedCondition([cancerConditions[0]]);
      } else {
        setSelectedCondition([conditions[0]]);
      }
      setHasInitialized(true);
    }
  }, [conditions, hasInitialized]);

  const trialsByCondition = useMemo(() => {
    if (!trialLandscapeData?.length) return [];
    
    const filtered = selectedCondition.length === 0
      ? []  // Return empty array when no conditions selected
      : trialLandscapeData.filter(trial => selectedCondition.includes(trial.condition));
    
    //console.log('Filtered trials by condition:', filtered); // Debug log
    return filtered;
  }, [trialLandscapeData, selectedCondition]);

  const { 
    targets, 
    targetTrialsSelected,
    allTrialData,
    modalities, 
    modalityColors, 
    loading 
  } = useDrugLandscapeData(trialsByCondition, targetCount);

  //console.log('All trial data from hook:', allTrialData); // Debug log

  // Only show the component if we're on the plot or table tab
  if (activeTab !== 'plot' && activeTab !== 'table') {
    return null;
  }

  return (
    <div className="space-y-4 pt-6">
      <ConditionSelect
        selectedCondition={selectedCondition}
        conditions={conditions}
        onChange={setSelectedCondition}
      />
      
      <div className="w-full">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            <button
              onClick={() => onTabChange('plot')}
              className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none ${
                activeTab === 'plot'
                  ? 'border-[#852A45] text-[#852A45]'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Target Landscape
            </button>
            <button
              onClick={() => onTabChange('table')}
              className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none ${
                activeTab === 'table'
                  ? 'border-[#852A45] text-[#852A45]'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Trials Table
            </button>
          </nav>
        </div>

        <div className="mt-6">
          {activeTab === 'plot' && (
            <TargetTrialPhasePlot
              targets={targets}
              trialData={targetTrialsSelected}
              modalities={modalities}
              modalityColors={modalityColors}
              loading={loading}
            />
          )}
          {activeTab === 'table' && (
            <TrialDataTable
              trialData={allTrialData}
              loading={loading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

TrialLandscape.propTypes = {
  initialTargetCount: PropTypes.number,
  activeTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired
};

export default TrialLandscape;
