import React, { useState, useMemo, useEffect, useContext, useRef } from 'react';
import ReactDOM from 'react-dom';
import FeedbackButton from './FeedbackButton';
import { FaEllipsisH } from 'react-icons/fa';
import Tooltip from '../../common/Tooltip';

export const CircularSections = ({ centerX, centerY, phaseConfig }) => (
  Object.entries(phaseConfig).map(([phase, config]) => (
    <circle
      key={phase}
      cx={centerX}
      cy={centerY}
      r={config.outerRadius}
      fill="none"
      stroke="#d0d0d0"
      strokeWidth="1"
    />
  ))
);

export const RadialLines = ({ targets, centerX, centerY }) => (
  targets.map((target, index) => {
    const angle = target.startAngle;
    const radianAngle = (angle * Math.PI) / 180;
    const endX = centerX + Math.cos(radianAngle) * 370; // Rmax (350) + 20
    const endY = centerY + Math.sin(radianAngle) * 370; // Rmax (350) + 20

    return (
      <line
        key={`radial-line-${index}`}
        x1={centerX}
        y1={centerY}
        x2={endX}
        y2={endY}
        stroke="#d0d0d0"
        strokeWidth="1"
        strokeDasharray="4 4"
      />
    );
  })
);

export const PhaseLabels = ({ centerX, centerY, phaseConfig }) => (
  Object.entries(phaseConfig).map(([phase, config]) => {
    const { innerRadius, outerRadius } = config;
    const labelRadius = (innerRadius + outerRadius) / 2;

    return (
      <text
        key={phase}
        x={centerX}
        y={centerY - labelRadius}
        textAnchor="middle"
        className="text-sm font-semibold fill-gray-700"
      >
        {phase}
      </text>
    );
  })
);

export const Legend = ({ x, y, width, modalities, modalityColors }) => (
  <div 
    style={{
      position: 'absolute',
      left: x,
      top: y,
      width: width,
      backgroundColor: 'white',
      border: '1px solid #d0d0d0',
      borderRadius: '4px',
      padding: '10px',
      zIndex: 10
    }}
  >
    <div className="text-sm font-semibold mb-2">Modalities</div>
    {modalities.map((modality, index) => (
      <div key={modality} className="flex items-center gap-2 mb-2">
        <div 
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: modalityColors[modality]
          }}
        />
        <span className="text-xs">{modality}</span>
      </div>
    ))}
  </div>
);

export const TargetLabels = ({ targets, centerX, centerY, Rmax, labelPositions }) => (
  targets.map((target, index) => {
    const nextTarget = targets[(index + 1) % targets.length];
    const angleToNext = ((nextTarget.startAngle - target.startAngle + 360) % 360);
    const offset = angleToNext / 2;
    const labelAngle = (target.startAngle + offset) % 360;
    const radianAngle = (labelAngle * Math.PI) / 180;
    const endX = centerX + Math.cos(radianAngle) * (Rmax + 80); 
    const endY = centerY + Math.sin(radianAngle) * (Rmax + 80); 

    // Split text into words
    const words = target.name.split(' ');
    const CHARS_PER_LINE = 15;
    let lines = [];
    let currentLine = words[0];

    // Create lines of text
    for(let i = 1; i < words.length; i++) {
      if (currentLine.length + words[i].length + 1 <= CHARS_PER_LINE) {
        currentLine += ' ' + words[i];
      } else {
        lines.push(currentLine);
        currentLine = words[i];
      }
    }
    lines.push(currentLine);

    return (
      <g key={target.name}>
        {lines.map((line, lineIndex) => (
          <text
            key={`${target.name}-${lineIndex}`}
            x={endX}
            y={endY + (lineIndex * 16 - (lines.length - 1) * 8)} // Center multi-line text vertically
            textAnchor="middle"
            className="text-sm fill-gray-700 font-medium"
            style={{ maxWidth: '120px' }}
          >
            {line}
          </text>
        ))}
      </g>
    );
  })
);

export const TrialMarkers = ({
  trials,
  hoveredTrial,
  clickedTrial,
  setHoveredTrial,
  setClickedTrial,
  onSuggestChanges,
  modalityColors
}) => {
  const markerRefs = useRef({});
  
  // Clear marker refs when component unmounts or trials change
  useEffect(() => {
    return () => {
      markerRefs.current = {};
    };
  }, [trials]);

  const handleTrialClick = (trial, e) => {
    e.stopPropagation();
    setClickedTrial(trial);
  };

  // Reset clicked trial when trials change
  useEffect(() => {
    setClickedTrial(null);
  }, [trials, setClickedTrial]);

  return (
    <>
      <g key={trials.map(t => t.id || t.nct_id).join('-')}>
        {trials.map((trial) => {
          const isActive = hoveredTrial === trial || clickedTrial === trial;
          const markerKey = trial.id || trial.nct_id;
          
          return (
            <g 
              key={markerKey}
              className="trial-marker"
              ref={el => {
                if (el) {
                  markerRefs.current[markerKey] = el;
                } else {
                  delete markerRefs.current[markerKey];
                }
              }}
            >
              <circle
                cx={trial.x}
                cy={trial.y}
                r={isActive ? "6" : "5"}
                fill={modalityColors[trial.modality]}
                stroke="white"
                strokeWidth={isActive ? "2" : "1"}
                style={{ 
                  cursor: 'pointer',
                  transition: 'all 0.2s ease-in-out'
                }}
                onMouseEnter={() => setHoveredTrial(trial)}
                onMouseLeave={() => setHoveredTrial(null)}
                onClick={(e) => handleTrialClick(trial, e)}
              />
              <text
                x={trial.x}
                y={trial.y - 10}
                textAnchor="middle"
                className="text-xs fill-gray-600"
                style={{
                  opacity: isActive ? 1 : 0.8,
                  transition: 'opacity 0.2s ease-in-out'
                }}
              >
                {trial.investigational_agent}
              </text>
            </g>
          );
        })}
      </g>
      {clickedTrial && (
        <TooltipContainer 
          trial={clickedTrial}
          markerRef={markerRefs.current[clickedTrial.id || clickedTrial.nct_id]}
          onClose={() => setClickedTrial(null)}
          onSuggestChanges={onSuggestChanges}
        />
      )}
    </>
  );
};

const TooltipContainer = ({ trial, markerRef, onClose, onSuggestChanges }) => {
  const [position, setPosition] = useState(null);
  
  useEffect(() => {
    if (markerRef) {
      const rect = markerRef.getBoundingClientRect();
      setPosition({
        left: rect.left + window.scrollX + rect.width / 2,
        top: rect.top + window.scrollY
      });
    }
  }, [markerRef]);

  if (!position) return null;

  return ReactDOM.createPortal(
    <div 
      style={{ 
        position: 'absolute',
        left: position.left + 20,
        top: position.top - 20,
        zIndex: 1000,
        transition: 'all 0.2s ease-out',
        opacity: position ? 1 : 0,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Tooltip
        data={{
          investigational_agent: trial.investigational_agent,
          phase: trial.phase,
          target: trial.target,
          modality: trial.modality,
          sponsor_names: trial.sponsor_names,
          nct_id: trial.nct_id
        }}
        isVisible={true}
        onClose={onClose}
        onSuggestChanges={onSuggestChanges}
      />
    </div>,
    document.body
  );
};

export const TargetCountControl = ({ targetCount, setTargetCount }) => (
  <div>
    <label htmlFor="targetCount" className="mr-2">
      Number of Targets:
    </label>
    <input
      id="targetCount"
      type="number"
      min="1"
      max="20"
      value={targetCount}
      onChange={(e) => setTargetCount(Number(e.target.value))}
      className="border p-1 w-16"
    />
  </div>
);
