import React from 'react';

const AboutTab = ({ onTabChange }) => {
  return (
    <div className="w-full max-w-3xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-lg p-8">
        <h2 className="text-2xl font-semibold text-[#852A45] mb-6">About This Demo</h2>
        
        <div className="space-y-4 text-gray-700">
          <p>
            This is a demo to showcase a small part of our biomedical data layer, generated by an ensemble of AI agents.
          </p>
          
          <p>
            Data on approved compounds and compounds in development are retrieved from OpenFDA and clinical-trials.gov. 
            Agent workflows then categorize the compounds and enhance information on molecular targets and modalities.
          </p>

          <p>
            Interested in our AI-driven data-layer to support your decision making? Our software can curate pulic data sources to your needs and integrate with your internal data. Do not hesitate to reach out to learn more.
          </p>

          <div className="mt-8 flex justify-center">
            <button
              onClick={() => onTabChange('feedback')}
              className="bg-[#852A45] text-white px-6 py-2 rounded-lg hover:bg-[#6d2238] transition-colors duration-200"
            >
              Get in Touch
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTab;
