import { useState, useEffect } from 'react';

export const transformTrialData = (trialLandscapeData, targetCount) => {
  // Debug log
  //console.log('Input data:', trialLandscapeData);

  // Map 'PHASE4' to 'APPROVED' and handle combined phases
  const cleanedData = trialLandscapeData.map((trial) => {
    let phase = trial.phase;
    
    // Handle special phase cases
    if (phase === 'PHASE4') {
      phase = 'APPROVED';
    } else if (phase === 'PHASE1/PHASE2') {
      phase = 'PHASE2';
    } else if (phase === 'PHASE2/PHASE3') {
      phase = 'PHASE3';
    }
    
    return { ...trial, phase };
  });

  // Group trials by target and compound
  const aggregatedTrials = cleanedData.reduce((acc, trial) => {
    const key = `${trial.target_short}-${trial.investigational_agent}`;
    const phaseOrder = {
      'APPROVED': 4,
      'PHASE3': 3,
      'PHASE2': 2,
      'PHASE1': 1
    };
    
    if (!acc[key]) {
      acc[key] = {
        ...trial,
        id: key,
        target: trial.target_short,
        investigational_agent: trial.investigational_agent,
        modality: trial.modality || 'Unknown',
        phase: trial.phase,
        sponsor_names: trial.sponsor_names,
        trials: [trial]
      };
    } else {
      // Check if this trial ID already exists
      const existingTrialIds = new Set(acc[key].trials.map(t => t.nct_id));
      if (!existingTrialIds.has(trial.nct_id)) {
        // Compare phases and keep the highest one
        const currentPhaseValue = phaseOrder[acc[key].phase] || 0;
        const newPhaseValue = phaseOrder[trial.phase] || 0;
        
        if (newPhaseValue > currentPhaseValue) {
          // Update the main trial data with the higher phase trial
          acc[key] = {
            ...trial,
            id: key,
            target: trial.target_short,
            investigational_agent: trial.investigational_agent,
            modality: trial.modality || 'Unknown',
            phase: trial.phase,
            sponsor_names: trial.sponsor_names,
            trials: [...acc[key].trials, trial]
          };
        } else {
          // Just add the trial to the trials array without updating the main data
          acc[key].trials.push(trial);
        }
      }
    }
    return acc;
  }, {});

  // Debug log
  //console.log('Aggregated trials:', aggregatedTrials);

  // Get all aggregated trials for the table view (no target filtering)
  const allTrialData = Object.values(aggregatedTrials);

  // Debug log
  //console.log('All trial data:', allTrialData);

  // Extract top targets using the aggregated data (for the plot view)
  const targetCompoundsMap = Object.values(aggregatedTrials).reduce((map, trial) => {
    const target = trial.target_short;
    const agent = trial.investigational_agent;
    if (
      target &&
      !['not target specific', 'not found', 'none', 'not applicable', 'information insufficient'].includes(target.toLowerCase()) &&
      agent
    ) {
      if (!map[target]) {
        map[target] = new Set();
      }
      map[target].add(agent.trim());
    }
    return map;
  }, {});

  const targetCounts = Object.entries(targetCompoundsMap).reduce(
    (counts, [target, compoundsSet]) => {
      counts[target] = compoundsSet.size;
      return counts;
    },
    {}
  );

  const sortedTargets = Object.entries(targetCounts)
    .sort((a, b) => b[1] - a[1])
    .slice(0, targetCount)
    .map(([target]) => target);

  const angleStep = 360 / sortedTargets.length;
  const updatedTargets = sortedTargets.map((target, index) => ({
    name: target,
    startAngle: index * angleStep,
    endAngle: (index + 1) * angleStep,
  }));

  // Transform aggregated data for plot (only top targets)
  const plotData = Object.values(aggregatedTrials)
    .map((trial) => {
      const targetObj = updatedTargets.find((t) => t.name === trial.target_short);
      if (!targetObj) return null;

      return {
        ...trial,
        target: trial.target_short,
        modality: trial.modality || 'Unknown',
      };
    })
    .filter(Boolean);

  // Filter out combined modalities (containing semicolons) from the legend
  const modalitiesSet = new Set(
    allTrialData
      .map((agent) => agent.modality || 'Unknown')
      .filter(modality => !modality.includes(';'))
  );
  const modalitiesArray = Array.from(modalitiesSet);

  const colors = [
    '#FF6B6B', '#4ECDC4', '#FFB347', '#9B59B6', '#06D6A0',
    '#118AB2', '#073B4C', '#EF476F', '#FFC43D', '#D4A373',
  ];

  const modalityColorMap = modalitiesArray.reduce((map, modality, index) => {
    map[modality] = colors[index % colors.length];
    return map;
  }, {});

  return {
    updatedTargets,
    transformedData: plotData,
    allTrialData,
    modalitiesArray,
    modalityColorMap,
  };
};

const useDrugLandscapeData = (trialsByCondition, targetCount) => {
  const [targets, setTargets] = useState([]);
  const [targetTrialsSelected, setTargetTrialsSelected] = useState([]); 
  const [allTrials, setAllTrials] = useState([]); 
  const [modalities, setModalities] = useState([]);
  const [modalityColors, setModalityColors] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (trialsByCondition.length > 0) {
      const { updatedTargets, transformedData: selectedTrials, allTrialData, modalitiesArray, modalityColorMap } =
        transformTrialData(trialsByCondition, targetCount);

      setTargets(updatedTargets);
      setTargetTrialsSelected(selectedTrials);
      setAllTrials(allTrialData);
      setModalities(modalitiesArray);
      setModalityColors(modalityColorMap);
      setLoading(false);
    } else {
      // Clear all data when no trials are provided
      setTargets([]);
      setTargetTrialsSelected([]);
      setAllTrials([]);
      setModalities([]);
      setModalityColors({});
      setLoading(false);
    }
  }, [trialsByCondition, targetCount]);

  return {
    targets,
    targetTrialsSelected, 
    allTrialData: allTrials,
    modalities,
    modalityColors,
    loading,
  };
};

export default useDrugLandscapeData;
