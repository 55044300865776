import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
  ArrowUpDown,
  ChevronLeft,
  ChevronRight,
  Settings,
  GripVertical,
  Download,
} from 'lucide-react';
import orderBy from 'lodash/orderBy';
import { unparse } from 'papaparse';

// Main DataTable component
const DataTable = ({
  data = [],
  columns: initialColumns = [],
  onExport,
  renderCell,
  uniqueRowId = 'id',
  showExport = false,
}) => {
  const tableRef = useRef(null);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [columns, setColumns] = useState(() => {
    return Array.isArray(initialColumns) ? initialColumns.map((col) => ({
      ...col,
      visible: col.visible !== undefined ? col.visible : true,
      width: col.width || 200,
    })) : [];
  });

  // Update columns when initialColumns changes
  useEffect(() => {
    if (Array.isArray(initialColumns)) {
      setColumns(initialColumns.map((col) => ({
        ...col,
        visible: col.visible !== undefined ? col.visible : true,
        width: col.width || 200,
      })));
    }
  }, [initialColumns]);

  const [showColumnMenu, setShowColumnMenu] = useState(false);
  const [resizing, setResizing] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [containerWidth, setContainerWidth] = useState(1200);
  const [containerLeft, setContainerLeft] = useState(0);
  const [resizingSide, setResizingSide] = useState(null);

  const handleContainerResizeStart = (side, e) => {
    e.preventDefault();

    setResizingSide({
      side,
      startX: e.clientX,
      startWidth: containerWidth,
      startLeft: containerLeft
    });
  };

  useEffect(() => {
    const handleContainerResize = (e) => {
      if (!resizingSide) return;

      if (resizingSide.side === 'right') {
        const newWidth = resizingSide.startWidth + (e.clientX - resizingSide.startX);
        setContainerWidth(Math.max(500, newWidth));
      } else if (resizingSide.side === 'left') {
        const diff = e.clientX - resizingSide.startX;
        const newLeft = resizingSide.startLeft + diff;
        // Keep the right side fixed by adjusting width oppositely
        const newWidth = resizingSide.startWidth - diff;
        
        if (newWidth >= 500) {
          setContainerLeft(newLeft);
          setContainerWidth(newWidth);
        }
      }
    };

    const handleResizeEnd = () => {
      setResizingSide(null);
    };

    if (resizingSide) {
      document.addEventListener('mousemove', handleContainerResize);
      document.addEventListener('mouseup', handleResizeEnd);
    }

    return () => {
      document.removeEventListener('mousemove', handleContainerResize);
      document.removeEventListener('mouseup', handleResizeEnd);
    };
  }, [resizingSide]);

  // Handle column resizing
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (resizing !== null) {
        const deltaX = e.clientX - resizing.startX;
        const newWidth = Math.max(100, resizing.initialWidth + deltaX);
        const nextColIndex = resizing.index + 1;

        setColumns((prev) => {
          const updatedColumns = [...prev];

          if (nextColIndex < prev.length) {
            const nextCol = prev[nextColIndex];
            const totalWidth = resizing.initialWidth + resizing.nextColWidth;
            const newNextColWidth = totalWidth - newWidth;

            if (newNextColWidth >= 100) {
              updatedColumns[resizing.index] = {
                ...prev[resizing.index],
                width: newWidth,
              };
              updatedColumns[nextColIndex] = {
                ...nextCol,
                width: newNextColWidth,
              };
            }
          } else {
            updatedColumns[resizing.index] = {
              ...prev[resizing.index],
              width: newWidth,
            };
          }

          return updatedColumns;
        });
      }
    };

    const handleMouseUp = () => {
      setResizing(null);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [resizing]);

  const handleResizeStart = (index, e) => {
    e.preventDefault();
    const initialWidth = columns[index].width;
    const nextColIndex = index + 1;
    const nextColWidth =
      nextColIndex < columns.length ? columns[nextColIndex].width : null;

    setResizing({
      index,
      startX: e.clientX,
      initialWidth,
      nextColWidth,
    });
  };

  // Toggle column visibility
  const toggleColumn = (index) => {
    setColumns((prev) =>
      prev.map((col, idx) =>
        idx === index ? { ...col, visible: !col.visible } : col
      )
    );
  };

  // Sorting function
  const sortedData = useMemo(() => {
    if (!sortConfig.key) return data;
    return orderBy(data, [sortConfig.key], [sortConfig.direction]);
  }, [data, sortConfig]);

  // Search function
  const filteredData = useMemo(() => {
    if (!searchTerm) return sortedData;
    return sortedData.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [sortedData, searchTerm]);

  // Pagination
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const paginatedData = useMemo(() => {
    const start = (currentPage - 1) * rowsPerPage;
    return filteredData.slice(start, start + rowsPerPage);
  }, [filteredData, currentPage, rowsPerPage]);

  // Sorting handler
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Default Export function
  const defaultExport = () => {
    const visibleCols = Array.isArray(columns) ? columns.filter((col) => col.visible) : [];

    const exportData = filteredData.map((row) => {
      const exportRow = {};
      visibleCols.forEach((col) => {
        exportRow[col.label] = row[col.key];
      });
      return exportRow;
    });

    const csv = unparse(exportData, {
      quotes: true,
      header: true,
    });

    const blob = new Blob(['\ufeff', csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'table-export.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExport = onExport || defaultExport;

  // Default renderCell function
  const defaultRenderCell = (column, value) => {
    return value;
  };

  const cellRenderer = renderCell || defaultRenderCell;

  return (
    <div className="relative" style={{ width: containerWidth }}>      
      <div
        className="absolute right-0 top-0 bottom-0 w-1 cursor-ew-resize hover:bg-blue-500"
        onMouseDown={(e) => handleContainerResizeStart('right', e)}
      />
      
      <div className="w-full space-y-4">
        {/* Controls */}
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="p-2 border rounded-lg"
            />
            <div className="relative">
              <button
                onClick={() => setShowColumnMenu(!showColumnMenu)}
                className="p-2 border rounded-lg flex items-center space-x-2"
              >
                <Settings className="h-4 w-4" />
                <span>Columns</span>
              </button>
              {showColumnMenu && (
                <div className="absolute z-10 mt-2 w-48 bg-white border rounded-lg shadow-lg">
                  <div className="p-2">
                    {Array.isArray(columns) && columns.map((column, index) => (
                      <label
                        key={column.key}
                        className="flex items-center space-x-2 p-2 hover:bg-gray-100"
                      >
                        <input
                          type="checkbox"
                          checked={column.visible}
                          onChange={() => toggleColumn(index)}
                        />
                        <span>{column.label}</span>
                      </label>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {showExport && (
              <button
                onClick={handleExport}
                className="p-2 border rounded-lg flex items-center space-x-2 hover:bg-gray-50"
              >
                <Download className="h-4 w-4" />
                <span>Export</span>
              </button>
            )}
          </div>
          <select
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(Number(e.target.value))}
            className="p-2 border rounded-lg"
          >
            <option value={5}>5 per page</option>
            <option value={10}>10 per page</option>
            <option value={20}>20 per page</option>
          </select>
        </div>

        {/* Pagination controls - moved up */}
        <div className="flex justify-between items-center">
          <div className="text-sm text-gray-600">
            Showing{' '}
            {(currentPage - 1) * rowsPerPage + 1} to{' '}
            {Math.min(currentPage * rowsPerPage, filteredData.length)} of{' '}
            {filteredData.length} results
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => setCurrentPage((p) => Math.max(1, p - 1))}
              disabled={currentPage === 1}
              className="p-2 border rounded-lg disabled:opacity-50"
            >
              <ChevronLeft className="h-4 w-4" />
            </button>
            <span className="px-4 py-2">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage((p) => Math.min(totalPages, p + 1))}
              disabled={currentPage === totalPages}
              className="p-2 border rounded-lg disabled:opacity-50"
            >
              <ChevronRight className="h-4 w-4" />
            </button>
          </div>
        </div>

        {/* Table */}
        <div className="border rounded-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table ref={tableRef} className="min-w-full">
              <thead className="bg-gray-100">
                <tr>
                  {Array.isArray(columns) && columns
                    .filter((col) => col.visible)
                    .map((column, index) => (
                      <th
                        key={column.key}
                        className="relative px-4 py-2 text-left font-medium text-gray-600 select-none"
                        style={{ 
                          width: column.width,
                          minWidth: column.minWidth || column.width,
                          maxWidth: column.width
                        }}
                      >
                        <div className="flex items-center space-x-1">
                          <div
                            className="flex-grow cursor-pointer"
                            onClick={() => handleSort(column.key)}
                          >
                            <div className="flex items-center space-x-1">
                              <span>{column.label}</span>
                              <ArrowUpDown className="h-4 w-4" />
                            </div>
                          </div>
                          <div
                            className="absolute right-0 top-0 bottom-0 w-4 cursor-col-resize flex items-center justify-center hover:bg-gray-300"
                            onMouseDown={(e) => handleResizeStart(index, e)}
                          >
                            <GripVertical className="h-4 w-4" />
                          </div>
                        </div>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((row, rowIndex) => {
                  const rowId = row[uniqueRowId] !== undefined ? row[uniqueRowId] : rowIndex;

                  return (
                    <tr key={rowId} className="border-t hover:bg-gray-50">
                      {Array.isArray(columns) && columns
                        .filter((col) => col.visible)
                        .map((column) => {
                          const cellId = `${rowId}-${column.key}`;
                          const isExpanded = expandedRows[cellId] || false;

                          const toggleExpand = () => {
                            setExpandedRows((prev) => ({
                              ...prev,
                              [cellId]: !isExpanded,
                            }));
                          };

                          return (
                            <td
                              key={column.key}
                              className="px-4 py-2 align-top"
                              style={{ 
                                width: column.width,
                                minWidth: column.minWidth || column.width,
                                maxWidth: column.width,
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                overflow: 'hidden'
                              }}
                            >
                              {cellRenderer(
                                column,
                                row[column.key],
                                rowId,
                                { isExpanded, toggleExpand },
                                row // Pass the entire row data here
                              )}
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DataTable;