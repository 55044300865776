import React, { createContext, useEffect, useState, useRef } from 'react';
import WebSocketManager from '../services/websocket/WebSocketManager';
import { parseMergedInfo } from '../utils/tableUtils';

export const WebSocketContext = createContext();
export const WebSocketProvider = ({ children }) => {
  const [compoundsData, setCompoundsData] = useState([]);
  const [targets, setTargets] = useState([]);
  const [proteinTissueData, setProteinTissueData] = useState([]);
  const [genesOfInterest, setGenesOfInterest] = useState([]);
  const [rnaTissueData, setRnaTissueData] = useState([]);
  const [signorData, setSignorData] = useState([]);
  const [trialLandscapeData, setTrialLandscapeData] = useState([]);
  const wsManagerRef = useRef(null);

  useEffect(() => {
    if (!wsManagerRef.current) {
//      wsManagerRef.current = new WebSocketManager('ws://34.234.198.209:80/api/ws/genes');

      wsManagerRef.current = new WebSocketManager('wss://triallandscape.navis-bio.com/api/ws/genes');
    //  wsManagerRef.current = new WebSocketManager('ws://localhost:8000/api/ws/genes');

      wsManagerRef.current.addMessageHandler('default', (data) => {
        console.log('Received initial connection data:', data);
      });

      wsManagerRef.current.connect();
    }
  }, []);

  useEffect(() => {
    const wsManager = wsManagerRef.current;
    if (!wsManager || !targets || targets.length === 0) return;

    const handleCompoundsData = (receivedData) => {
      if (!receivedData.data || !Array.isArray(receivedData.data)) {
        console.error('Invalid data format:', receivedData);
        return;
      }

      const processedData = receivedData.data.map((item, index) => ({
        ...item,
        id: index + 1,
        isApproved: item.isApproved === true || item.isApproved === 'true',
        merged_info: parseMergedInfo(item.merged_info),
      }));

      setCompoundsData(processedData);
    };

    wsManager.addMessageHandler('existing_compounds', handleCompoundsData);
    wsManager.send('existing_compounds', { genes: targets });

    return () => {
      wsManager.removeMessageHandler('existing_compounds', handleCompoundsData);
    };
  }, [targets]);

  useEffect(() => {
    const wsManager = wsManagerRef.current;
    if (!wsManager || !genesOfInterest || genesOfInterest.length === 0) return;

    const handleProteinTissueData = (receivedData) => {
      if (!receivedData.data || !Array.isArray(receivedData.data)) {
        console.error('Invalid data format:', receivedData);
        return;
      }

      setProteinTissueData(receivedData.data);
    };

    wsManager.addMessageHandler('protein_tissue', handleProteinTissueData);
    wsManager.send('protein_tissue', { genes: genesOfInterest });

    return () => {
      wsManager.removeMessageHandler('protein_tissue', handleProteinTissueData);
    };
  }, [genesOfInterest]);

  useEffect(() => {
    const wsManager = wsManagerRef.current;
    if (!wsManager || !genesOfInterest || genesOfInterest.length === 0) return;

    const handleRnaTissueData = (receivedData) => {
      if (!receivedData.data || !Array.isArray(receivedData.data)) {
        console.error('Invalid data format:', receivedData);
        return;
      }
      setRnaTissueData(receivedData.data);
    };

    wsManager.addMessageHandler('rna_tissue', handleRnaTissueData);
    wsManager.send('rna_tissue', { genes: genesOfInterest });

    return () => {
      wsManager.removeMessageHandler('rna_tissue', handleRnaTissueData);
    };
  }, [genesOfInterest]);

  useEffect(() => {
    const wsManager = wsManagerRef.current;
    if (!wsManager || !genesOfInterest || genesOfInterest.length === 0) return;

    const handleSignorData = (receivedData) => {
      if (!receivedData.data || !Array.isArray(receivedData.data)) {
        console.error('Invalid data format:', receivedData);
        return;
      }
      setSignorData(receivedData.data);
    };

    wsManager.addMessageHandler('signor', handleSignorData);
    wsManager.send('signor', { genes: genesOfInterest });

    return () => {
      wsManager.removeMessageHandler('signor', handleSignorData);
    };
  }, [genesOfInterest]);

  useEffect(() => {
    const wsManager = wsManagerRef.current;
    if (!wsManager) return;

    const handleTrialLandscapeData = (receivedData) => {
      if (!receivedData.data || !Array.isArray(receivedData.data)) {
        console.error('Invalid trial landscape data format:', receivedData);
        return;
      }
      setTrialLandscapeData(receivedData.data);
    };

    wsManager.addMessageHandler('trial_landscape', handleTrialLandscapeData);
    wsManager.send('trial_landscape', { genes: 'n/a', indication: 'n/a' });

    return () => {
      wsManager.removeMessageHandler('trial_landscape', handleTrialLandscapeData);
    };
  }, []);
  const sendFeedback = (feedbackMessage, userEmail, rowData) => {
    const feedbackData = {
      type: 'feedback',
      user_message: feedbackMessage,
      user_email: userEmail,
      metadata: {
        row_content: rowData // Include the full rowData object directly
      },
      category: 'trial_landscape', // You can adjust this as needed
    };

    wsManagerRef.current.send('feedback', feedbackData);
  };

  const sendMessage = (message, email) => {
    wsManagerRef.current.send('send_message', {
      type: 'send_message',
      message: message,
      user_email: email
    });
  };

  return (
    <WebSocketContext.Provider
      value={{
        compoundsData,
        targets,
        setTargets,
        proteinTissueData,
        genesOfInterest,
        setGenesOfInterest,
        rnaTissueData,
        signorData,
        trialLandscapeData,
        sendFeedback,
        sendMessage
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
