import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FaEllipsisH, FaCopy, FaEdit } from 'react-icons/fa';

const ActionMenu = ({ data, onSuggestChanges }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyError, setCopyError] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef(null);
  const menuRef = useRef(null);

  const updatePosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setMenuPosition({
        top: rect.top,
        left: rect.right + 5
      });
    }
  };

  const handleCopy = async () => {
    const expandedInfo = data.expanded_data ? Object.entries(data.expanded_data)
      .filter(([key]) => !['investigational_agent', 'phase', 'target', 'modality', 'sponsor_names'].includes(key))
      .map(([key, value]) => `${key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}: ${value}`)
      .join('\n') : '';

    const info = `Trial Information
----------------
Agent: ${data.investigational_agent}
Phase: ${data.phase}
Target: ${data.target}
Modality: ${data.modality}
Sponsor: ${Array.isArray(data.sponsor_names) ? data.sponsor_names.join(', ') : data.sponsor_names}${expandedInfo ? '\n\nExpanded Information\n----------------\n' + expandedInfo : ''}`;
    
    try {
      await navigator.clipboard.writeText(info);
      setCopySuccess(true);
      setCopyError(false);
      setTimeout(() => {
        setCopySuccess(false);
        setIsMenuOpen(false);
      }, 500);
    } catch (err) {
      console.error('Failed to copy:', err);
      setCopyError(true);
      setTimeout(() => {
        setCopyError(false);
        setIsMenuOpen(false);
      }, 500);
      
      // Fallback for browsers that don't support clipboard API
      try {
        const textArea = document.createElement('textarea');
        textArea.value = info;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        setCopySuccess(true);
        setCopyError(false);
        setTimeout(() => {
          setCopySuccess(false);
          setIsMenuOpen(false);
        }, 500);
      } catch (fallbackErr) {
        console.error('Fallback copy failed:', fallbackErr);
      }
    }
  };

  const handleSuggestChanges = () => {
    onSuggestChanges(data);
    setIsMenuOpen(false);
  };

  const toggleMenu = (e) => {
    e.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (isMenuOpen) {
      updatePosition();
      window.addEventListener('scroll', updatePosition, true);
      window.addEventListener('resize', updatePosition);
    }

    return () => {
      window.removeEventListener('scroll', updatePosition, true);
      window.removeEventListener('resize', updatePosition);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) &&
          buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        onClick={toggleMenu}
        className="p-1.5 hover:bg-gray-100 rounded-full text-gray-600 transition-colors"
        title="More options"
      >
        <FaEllipsisH className="w-3.5 h-3.5" />
      </button>
      {isMenuOpen && (
        <div
          ref={menuRef}
          className="fixed z-[1000]"
          style={{
            top: `${menuPosition.top}px`,
            left: `${menuPosition.left}px`
          }}
        >
          <div className="bg-white rounded-lg shadow-lg border border-gray-200 py-2 min-w-[150px]">
            <button
              onClick={handleCopy}
              className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 flex items-center gap-2"
            >
              <FaCopy className="w-4 h-4" />
              {copySuccess ? 'Copied!' : copyError ? 'Copy failed' : 'Copy info'}
            </button>
            <button
              onClick={handleSuggestChanges}
              className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 flex items-center gap-2"
            >
              <FaEdit className="w-4 h-4" />
              Suggest changes
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionMenu;
