import React, { useState } from 'react';
import FeedbackModal from '../../common/FeedbackModal';
import ActionMenu from '../../common/ActionMenu';

const FeedbackButton = ({ rowId, rowData }) => {
  const [showModal, setShowModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleModalClose = (success) => {
    setShowModal(false);
    if (success) {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 1000);
    }
  };

  const handleSuggestChanges = (data) => {
    setShowModal(true);
  };

  return (
    <>
      <ActionMenu 
        data={rowData}
        onSuggestChanges={handleSuggestChanges}
      />

      <FeedbackModal
        isOpen={showModal}
        onClose={handleModalClose}
        rowData={rowData}
      />

      {showSuccess && (
        <div className="fixed inset-0 flex items-center justify-center z-[99999999]">
          <div className="bg-green-50 text-green-700 px-4 py-2 rounded-lg shadow-lg border border-green-200 flex items-center gap-2">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
            <span className="font-medium">Suggestion sent. Thank you!</span>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackButton;