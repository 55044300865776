import React, { useState, useMemo, useCallback, useContext } from 'react';
import { preparePhaseConfig, calculateTrialPositions } from '../helpers/target_trial_phase_plot_helpers';
import { useLabelSimulation } from '../hooks/useLabelSimulation';
import {
  CircularSections,
  RadialLines,
  PhaseLabels,
  Legend,
  TargetLabels,
  TrialMarkers,
} from './TargetTrialPhasePlotComponents';
import { WebSocketContext } from '../../../context/WebSocketContext';
import FeedbackModal from '../../common/FeedbackModal';

const phases = ['APPROVED', 'PHASE3', 'PHASE2', 'PHASE1'];

const TargetTrialPhasePlot = ({
  trialData,
  loading,
  targets,
  targetCount,
  modalities,
  modalityColors
}) => {
  const [hoveredTrial, setHoveredTrial] = useState(null);
  const [clickedTrial, setClickedTrial] = useState(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackData, setFeedbackData] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const { sendFeedback } = useContext(WebSocketContext);

  const handleFeedbackClose = (success) => {
    setShowFeedbackModal(false);
    if (success) {
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 1000);
    }
  };

  const handleSuggestChanges = (data) => {
    setFeedbackData(data);
    setShowFeedbackModal(true);
  };

  // Calculate space needed for labels
  const FIXED_LABEL_WIDTH = 120; // Width in pixels for labels
  const SAFETY_MARGIN = 20; // Small margin for safety
  const plotWidth = 1000;
  const legendWidth = 250;
  const plotToLegendSpace = 100;
  const rightMargin = 100;
  
  // The left-most point of the plot can be at FIXED_LABEL_WIDTH + SAFETY_MARGIN
  const leftMargin = FIXED_LABEL_WIDTH + SAFETY_MARGIN;
  const width = plotWidth + plotToLegendSpace + legendWidth + rightMargin;
  const height = 800 + 60; // Reduced margin since we don't need to calculate based on label length

  // Position the center point at the minimum possible distance from left
  const centerX = leftMargin + 350;
  const centerY = height / 2;
  const Rmax = 350;

  const phaseConfig = useMemo(() => preparePhaseConfig(phases, Rmax), [phases, Rmax]);
  const trials = useMemo(() => {
    //console.log('TargetTrialPhasePlot - Received trialData:', trialData?.length || 0, 'trials');
    //console.log('TargetTrialPhasePlot - Received targets:', targets?.length || 0, 'targets');
    return calculateTrialPositions(trialData, targets, phaseConfig, centerX, centerY);
  }, [trialData, targets, phaseConfig, centerX, centerY]);

  const targetPositions = useMemo(() => {
    return targets.map(target => ({
      x: centerX + Math.cos(target.startAngle * Math.PI / 180) * (Rmax + 30),
      y: centerY + Math.sin(target.startAngle * Math.PI / 180) * (Rmax + 30),
      target
    }));
  }, [targets, centerX, centerY, Rmax]);

  const labelPositions = useLabelSimulation(targetPositions);

  // Handle click outside of trials
  const handleSvgClick = useCallback((event) => {
    // Get the clicked element
    const clickedElement = event.target;
    
    // Check if the clicked element is part of a trial marker, tooltip, or tooltip content
    const isTrialElement = clickedElement.closest('.trial-marker, .tooltip-container');
    
    // If click is not on a trial marker or tooltip, clear the clicked trial
    if (!isTrialElement) {
      setClickedTrial(null);
    }
  }, []);

  const SuccessMessage = () => (
    <div 
      className="fixed inset-0 flex items-center justify-center"
      style={{ zIndex: 99999999999 }}
      onClick={() => setShowSuccess(false)}
    >
      <div 
        className="bg-green-50 text-green-700 px-4 py-2 rounded-lg shadow-lg border border-green-200 flex items-center gap-2"
        onClick={(e) => e.stopPropagation()}
      >
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
        <span className="font-medium">Suggestion sent. Thank you!</span>
      </div>
    </div>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  // Don't render anything if there's no data
  if (!trialData?.length && !targets?.length) {
    return null;
  }

  return (
    <div className="relative w-full h-full">
      <div style={{ position: 'relative', overflow: 'visible', width: width, height: height }}>
        <svg
          width={width}
          height={height}
          style={{ 
            overflow: 'visible', 
            position: 'relative', 
            zIndex: 1 
          }}
          onClick={handleSvgClick}
        >
          <CircularSections
            centerX={centerX}
            centerY={centerY}
            phaseConfig={phaseConfig}
          />
          <RadialLines
            targets={targets}
            centerX={centerX}
            centerY={centerY}
          />
          <PhaseLabels
            centerX={centerX}
            centerY={centerY}
            phaseConfig={phaseConfig}
          />
          <TargetLabels
            targets={targets}
            centerX={centerX}
            centerY={centerY}
            Rmax={Rmax}
            labelPositions={labelPositions}
          />
          <TrialMarkers
            trials={trials}
            hoveredTrial={hoveredTrial}
            clickedTrial={clickedTrial}
            setHoveredTrial={setHoveredTrial}
            setClickedTrial={setClickedTrial}
            onSuggestChanges={handleSuggestChanges}
            modalityColors={modalityColors}
          />
        </svg>

        <Legend
          x={centerX + Rmax + plotToLegendSpace}
          y={50}
          width={legendWidth}
          modalities={modalities}
          modalityColors={modalityColors}
          style={{ position: 'absolute', zIndex: 2 }}
        />
      </div>

      <FeedbackModal
        isOpen={showFeedbackModal}
        onClose={handleFeedbackClose}
        rowData={feedbackData}
        category="target_compound_plot"
        sendFeedback={sendFeedback}
      />

      {showSuccess && <SuccessMessage />}
    </div>
  );
};

export default TargetTrialPhasePlot;