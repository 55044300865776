import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const MergedInfoCell = ({ mergedInfo, rowId }) => {
  const [isExpanded, setIsExpanded] = useState(() => {
    const savedState = localStorage.getItem(`mergedInfoExpanded_${rowId}`);
    return savedState ? JSON.parse(savedState) : false;
  });

  useEffect(() => {
    localStorage.setItem(`mergedInfoExpanded_${rowId}`, JSON.stringify(isExpanded));
  }, [isExpanded, rowId]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <span>{`Trials in ${mergedInfo.length} indications`}</span>
        {mergedInfo.length > 1 && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="ml-2 text-gray-500 hover:text-gray-700"
          >
            {isExpanded ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
          </button>
        )}
      </div>

      {isExpanded && mergedInfo.length > 1 && (
        <div className="mt-2 space-y-2">
          {mergedInfo.map((entry, index) => (
            <div key={index} className="space-y-1 border-b pb-2">
              <div className="text-sm font-medium text-gray-500">{entry.disease_name}</div>
              <div className="text-sm">Phase: {entry.phase}</div>
              {entry.start_date && <div className="text-sm">Start Date: {entry.start_date}</div>}
              {entry.status && <div className="text-sm">Status: {entry.status}</div>}
              <div className="text-sm">
                URLs:
                {entry.url.map((url, idx) => (
                  <div key={idx}>
                    <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                      {url}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MergedInfoCell;
