// src/context/TargetsContext.js
import React, { createContext, useState } from 'react';

export const TargetsContext = createContext();

export const TargetsProvider = ({ children }) => {
  const [targets, setTargets] = useState([]);
  const [indicationsOfInterest, setIndicationsOfInterest] = useState([]);

  return (
    <TargetsContext.Provider
      value={{
        targets,
        setTargets,
        indicationsOfInterest,
        setIndicationsOfInterest
      }}
    >
      {children}
    </TargetsContext.Provider>
  );
};
