import React from 'react';
import { parse, unparse } from 'papaparse';
import { ChevronDown, ChevronUp } from 'lucide-react';

export {
  renderTableCell,
  handleExport,
};

// Function to calculate the maximum phase from the trials array
const calculateMaxPhase = (trials) => {
  return trials.reduce((max, trial) => {
    const phaseString = trial.phase.toLowerCase();
    let phaseNumber = 0;

    if (phaseString.includes('phase')) {
      const phases = phaseString.match(/\d+\/\d+|\d+/g);
      if (phases) {
        phaseNumber = Math.max(
          ...phases.map((phase) => {
            if (phase.includes('/')) {
              const [start, end] = phase.split('/').map(Number);
              return (start + end) / 2;
            }
            return Number(phase);
          })
        );
      }
    }

    return phaseNumber > max ? phaseNumber : max;
  }, 0);
};

// Helper function to format text with clickable links
const formatSourceWithLinks = (sourceText) => {
  // Updated regex to better handle URLs with parentheses
  const urlPattern = /https?:\/\/(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+/g;
  let lastIndex = 0;
  const parts = [];
  let match;

  while ((match = urlPattern.exec(sourceText)) !== null) {
    // Add text before the URL
    const beforeUrl = sourceText.slice(lastIndex, match.index);
    if (beforeUrl) {
      parts.push(<span key={`text-${lastIndex}`}>{beforeUrl}</span>);
    }

    // Add the URL as a link
    const url = match[0];
    parts.push(
      <a
        key={`link-${match.index}`}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 hover:underline"
      >
        {url}
      </a>
    );

    lastIndex = match.index + match[0].length;
  }

  // Add any remaining text
  if (lastIndex < sourceText.length) {
    parts.push(
      <span key={`text-${lastIndex}`}>
        {sourceText.slice(lastIndex)}
      </span>
    );
  }

  return <div>{parts}</div>;
};

// Custom cell renderer for DataTable
const renderTableCell = (column, value, cellId, { isExpanded, toggleExpand }, rowData) => {
  if (column.key === 'trials') {
    // Ensure we have a trials array
    const trials = value || [];
    
    // Rendering logic for the 'trials' column
    return (
      <div className="relative w-full">
        <button
          onClick={toggleExpand}
          className="absolute right-0 top-0 p-1"
        >
          {isExpanded ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
        </button>

        <div className="pr-8">
          {!isExpanded ? (
            <span>{trials.length} trials, Max Phase: {calculateMaxPhase(trials)}</span>
          ) : (
            <div className="mt-2">
              {trials.map((trial) => (
                <div key={trial.nct_id} className="mb-2 border-b pb-2">
                  <div>
                    <strong>Trial ID:</strong>{' '}
                    <a
                      href={`https://clinicaltrials.gov/ct2/show/${trial.nct_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                    >
                      {trial.nct_id}
                    </a>
                  </div>
                  <div>
                    <strong>Title:</strong> {trial.brief_title}
                  </div>
                  <div>
                    <strong>Phase:</strong> {trial.phase}
                  </div>
                  <div>
                    <strong>Start Date:</strong> {trial.start_date}
                  </div>
                  <div>
                    <strong>Regimens:</strong> {trial.regimens}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  } else if (column.key === 'target') {
    // Ensure we have a value
    const targetValue = value || '';
    
    // Rendering logic for the 'target' column
    return (
      <div className="relative w-full overflow-hidden">
        <button
          onClick={toggleExpand}
          className="absolute right-0 top-0 p-1 z-10"
        >
          {isExpanded ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
        </button>

        <div className="pr-8 break-words overflow-hidden">
          {!isExpanded ? (
            <span>{targetValue}</span>
          ) : (
            <div className="mt-2">
              <div className="break-words">
                <strong>Target Name:</strong> {rowData.target_name}
              </div>
              <div className="break-words">
                <strong>Interaction:</strong> {rowData.target_compound_interaction}
              </div>
              <div className="break-words">
                <strong>Directionality:</strong> {rowData.target_compound_directionality}
              </div>
              <div className="break-words">
                <strong>Source:</strong> {formatSourceWithLinks(rowData.source_target)}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else if (column.key === 'modality') {
    // Ensure we have a value
    const modalityValue = value || '';
    
    return (
      <div className="relative w-full overflow-hidden">
        <button
          onClick={toggleExpand}
          className="absolute right-0 top-0 p-1 z-10"
        >
          {isExpanded ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
        </button>

        <div className="pr-8 break-words overflow-hidden">
          {!isExpanded ? (
            <span>{modalityValue}</span>
          ) : (
            <div className="mt-2">
              <div className="break-words">
                <strong>Modality:</strong> {modalityValue}
              </div>
              {rowData.modality_description && (
                <div className="break-words">
                  <strong>Description:</strong> {rowData.modality_description}
                </div>
              )}
              {rowData.modality_evidence && (
                <div className="break-words">
                  <strong>Evidence:</strong> {formatSourceWithLinks(rowData.modality_evidence)}
                </div>
              )}
              {rowData.source_modality && (
                <div className="break-words">
                  <strong>Source:</strong> {formatSourceWithLinks(rowData.source_modality)}
                </div>
              )}
              {rowData.modality_html && (
                <div 
                  className="break-words mt-2 p-2 bg-gray-50 rounded"
                  dangerouslySetInnerHTML={{ __html: rowData.modality_html }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return value || '';
  }
};

// Custom export function for DataTable
const handleExport = (data) => {
  const visibleColumns = [
    { key: 'investigational_agent', label: 'Compound Name' },
    { key: 'target', label: 'Target' },
    { key: 'modality', label: 'Modality' },
    { key: 'phase', label: 'Phase' },
    { key: 'trials', label: 'Trial Information' },
  ];

  const exportData = data.map((row) => {
    const exportRow = {};
    visibleColumns.forEach((col) => {
      if (col.key === 'trials') {
        // Flatten trial information into a string
        exportRow[col.label] = row[col.key]
          .map(
            (trial) =>
              `Trial ID: ${trial.nct_id}, Title: ${trial.brief_title}, Phase: ${trial.phase}, Start Date: ${trial.start_date}, Regimens: ${trial.regimens}`
          )
          .join(' | ');
      } else {
        exportRow[col.label] = row[col.key];
      }
    });
    return exportRow;
  });

  const csv = unparse(exportData, {
    quotes: true,
    header: true,
  });

  const blob = new Blob(['\ufeff', csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', 'table-export.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
